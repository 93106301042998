<template>
    <div class="field-group">
        <v-row class="my-0 py-0">
            <v-col cols="12">
                <!-- <code>{{field.id}}</code> -->
                <div class="subtitle-2 font-weight-black mb-0">{{i18n(field, 'label')}}</div>
                <div class="caption">{{i18n(field, 'desc')}}</div>
            </v-col>
            <v-col
                v-for="f in field.fields"
                :key="f.id"
                cols="12"
                class="mt-0 py-0"
                :sm="f.width ? f.width.sm : 12"
                :md="f.width ? f.width.md : 12"
            >
                <div :key="f.id">
                    <field-types :form="form" :field="f"></field-types>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    export default {
        name: 'field-group',
        components: {
            FieldTypes: () => import('./FieldTypes.vue')
        },
        props: {
            field: {
                type: Object,
                required: true
            },
            form: {
                type: Object,
                required: true
            }
        },
        data: () => ({
        }),
        mounted () {
            // console.log(this.field.fields)
        },
        methods: {
            i18n (obj, key) {
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            }
        }
    }
</script>
