<template>
    <div class="forms-view">
        
        <v-container>
            <v-row>
                <v-col align="center">
                    <v-responsive max-width="1024px" align="left" class="mb-80">
                        <div class="pa-12 bg-primary" border rounded>
                            <div class="d-flex flex-row mb-6 bg-surface-variant gap-16">
                                <v-text-field v-model="text[0]" class="text-cell" @paste="pasteRow" @input="correctPaste" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[1]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[2]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[3]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[4]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[5]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[6]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[7]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[8]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[9]" class="text-cell" variant="outlined"></v-text-field>
                            </div>

                            <div class="d-flex flex-row mb-6 bg-surface-variant gap-16">
                                <v-text-field v-model="text[10]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[11]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[12]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[13]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[14]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[15]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[16]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[17]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[18]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[19]" class="text-cell" variant="outlined"></v-text-field>
                            </div>


                            <div class="d-flex flex-row mb-6 bg-surface-variant gap-16">
                                <v-text-field v-model="text[20]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[21]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[22]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[23]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[24]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[25]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[26]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[27]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[28]" class="text-cell" variant="outlined"></v-text-field>
                                <v-text-field v-model="text[29]" class="text-cell" variant="outlined"></v-text-field>
                            </div>
                        </div>
                    </v-responsive>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'FormsView',
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        text: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        pasted: false,
        firstValue: ''
    }),
    methods: {
        pasteRow (e) {
            if (e.target.type === "text") {
                var data = e.clipboardData.getData('Text');

                data = data.split('\t');

                for (var i = 0; i < this.text.length; i++) {
                    this.text[i] = data[i]
                }

                [].forEach.call(document.querySelectorAll(".text-cell"), (node, index) => {
                    if (index === 0) {
                        this.firstValue = data[0];
                    }
                    this.pasted = true;
                    this.text[index] = data[index];
                });
            }
        },
        correctPaste () {
            if (this.pasted) {
                this.pasted = false;
                this.text[0] = this.firstValue;
            }
        }
    }
}
</script>

<style>
    .gap-16 {
        gap: 16px;
    }
</style>
