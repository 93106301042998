<template>
    <div>
        <!-- <code>{{field.id}}</code> -->
        <validation-provider
            v-if="field.type === 'text' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="field.label"
            :rules="{
                required: field.required
            }"
        >
            <v-text-field
                v-model="field.value"
                :label="i18n(field, 'label')"
                filled
                validate-on="blur"
                dense
                :required="field.required"
                :error-messages="i18nErrors(errors)"
            ></v-text-field>

        </validation-provider>

        <validation-provider
            v-if="field.type === 'mail' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="i18n(field, 'label')"
            :rules="{
                required: field.required,
                email: 'email'
            }"
        >
            <v-text-field
                v-model="field.value"
                :label="i18n(field, 'label')"
                filled
                dense
                required
                :error-messages="i18nErrors(errors)"
            ></v-text-field>

        </validation-provider>

        <validation-provider
            v-if="field.type === 'phone' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="field.label"
            :rules="{
                required: field.required,
                phone: 'phone'
            }"
        >
            <v-text-field
                v-model="field.value"
                :label="i18n(field, 'label')"
                filled
                dense
                required
                :error-messages="i18nErrors(errors)"
            ></v-text-field>

        </validation-provider>

        <validation-provider
            v-if="field.type === 'combobox' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="field.label"
            :rules="{
                required: field.required
            }"
        >
            <v-combobox
                v-model="field.value"
                :items="field.name === 'categorie-expozant' ? categoriiExpozant : items"
                :label="i18n(field, 'label')"
                multiple
                filled
                dense
                required
                :hint="i18n(field, 'hint')"
                persistent-hint
                small-chips
                :error-messages="i18nErrors(errors)"
            ></v-combobox>
            
        </validation-provider>

        <validation-provider
            v-if="field.type === 'combobox2' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="field.label"
            :rules="{
                required: field.required
            }"
        >
            <v-combobox
                v-model="field.value"
                :items="field.items.map(item => i18n(item, 'label'))"
                :label="i18n(field, 'label')"
                multiple
                filled
                dense
                required
                :hint="i18n(field, 'hint')"
                persistent-hint
                small-chips
                :error-messages="i18nErrors(errors)"
            ></v-combobox>
            
        </validation-provider>

        <validation-provider
            v-if="field.type === 'radio' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="i18n(field, 'label')"
            :rules="{
                required: field.required
            }"
        >
            <v-radio-group v-model="field.value" :error-messages="i18nErrors(errors)" required >
                <template v-slot:label>
                    <div class="subtitle-1 font-weight-black">{{i18n(field, 'label')}}</div>
                </template>
                <v-radio
                    v-for="item in field.items"
                    :key="item.value"
                    :label="i18n(item, 'label')"
                    :value="item.value"
                >
                    <template v-slot:label>
                        <div>
                            <div class="font-weight-medium">{{i18n(item, 'label')}}</div>
                            <div class="font-weight-regular caption">{{i18n(item, 'hint')}}</div>
                        </div>
                    </template>
                </v-radio>
            </v-radio-group>
            
        </validation-provider>

        <validation-provider
            v-if="field.type === 'select' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="i18n(field, 'label')"
            :rules="{
                required: field.required
            }"
        >
            <v-select
                v-model="field.value"
                :label="i18n(field, 'label')"
                :items="field.items"
                item-text="label"
                item-value="value"
                :hint="i18n(field, 'hint')"
                persistent-hint
                filled
                :error-messages="errors"
                required
            ></v-select>
            
        </validation-provider>

        <validation-provider
            v-if="field.type === 'checkbox' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="field.label"
            :rules="{
                required: field.required
            }"
        >
            <div class="subtitle-1 font-weight-black">{{i18n(field, 'label')}}</div>
            <div class="mb-6">
                <v-checkbox
                    v-model="field.value"
                    v-for="item in field.items"
                    :key="item.value"
                    :label="i18n(item, 'label')"
                    :value="item.value"
                    :messages="item.hint"
                    :id="item.value"
                    :disabled="(field.conditions && field.conditions.mode === 'disable' ? conditions(field, field.conditions) : false)"
                    :hide-details="!!!item.hint"
                    @change="checkboxChange"
                    multiple
                    :error-messages="i18nErrors(errors)"
                ></v-checkbox>
            </div>
            
        </validation-provider>

        <validation-provider
            v-if="field.type === 'number' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)"
            v-slot="{ errors }"
            :name="field.label"
            :rules="{
                required: field.required
            }"
        >
            <v-text-field
                v-model="field.value"
                :label="i18n(field, 'label')"
                filled
                dense
                required
                :hint="field.hint"
                type="number"
                :min="field.min"
                :error-messages="errors"
            ></v-text-field>
        </validation-provider>

        <div v-if="field.type === 'paragraph' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)">
            <p class="text-body-2">{{i18n(field, 'value')}}</p>
        </div>

        <div v-if="field.type === 'price' && (field.conditions && field.conditions.mode === 'show' ? conditions(field, field.conditions) : true)">
            <div class="subtitle-2">{{i18n(field, 'label')}}
            <v-chip
              class="ma-2"
              color="primary"
            >
                <span v-if="field.calc">
                    {{calc(field, field.calc).toFixed(2)}} {{i18n(field, 'suffix')}}
                </span>
                <span v-else-if="field.inheritValue">
                    {{inheritValue(field).toFixed(2)}} {{i18n(field, 'suffix')}}
                </span>
                <span v-else-if="field.variableValue">
                    {{variableValue(field).toFixed(2)}} {{i18n(field, 'suffix')}}
                </span>
                <span v-else>
                    {{field.value.toFixed(2)}}{{i18n(field, 'suffix')}}
                </span>
            </v-chip>
            </div>
        </div>

        <div v-if="field.type === 'fieldgroup'">
            <field-group :form="form" :field="field"></field-group>
        </div>
       
    </div>
</template>

<script>

    import { required, email, regex } from 'vee-validate/dist/rules';
    import { extend, ValidationProvider, setInteractionMode } from 'vee-validate';
    import FieldGroup from './FieldGroup.vue'

    setInteractionMode('eager');

    extend('required', {
        ...required,
        message: 'required',
    })

    extend('email', {
        ...email,
        message: 'email',
    })

    extend('regex', {
        ...regex,
        message: 'phone',
    })

    extend('phone', {
        validate: value => {
            const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
            return phoneRegex.test(value);
        },
        message: 'phone'
    })

  export default {
    name: 'field-types',
    components: {
        ValidationProvider,
        FieldGroup
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        items: [{
            header: 'Selecteaza o marca sau creaza una'
        }, {
            text: 'NHR AGROPARTNERS',
            color: 'blue',
        }, {
            text: 'CARGILL FURAJ SI NUTRITIE',
            color: 'red',
        }],
        categoriiExpozant: [{
            header: 'Selecteaza o categorie sau creaza una'
        }, {
            text: 'Producător',
            color: 'blue',
        }, {
            text: 'Importator',
            color: 'red',
        }, {
            text: 'Distribuitor',
            color: 'red',
        }, {
            text: 'Prestări servicii',
            color: 'red',
        }, {
            text: 'Asociaţie',
            color: 'red',
        }]
    }),
    mounted () {
    },
    methods: {
        checkboxChange (e) {
            // console.log(e)
            // console.log(this.form)
        },
        findObjectById (id, object) {
            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    if (object[key] && typeof object[key] === "object") {
                        const result = this.findObjectById(id, object[key]);
                        if (result) {
                            return result;
                        }
                    }
                    if (object[key] && object[key].id === id) {
                        return object[key];
                    }
                }
            }
        },
        calc (field, calc) {
            const arr = [];
            for (const key in calc) {
                if (calc[key] === '*') {
                    arr.push('*');
                } else if (calc[key] === '+') {
                    arr.push('+');
                } else if (calc[key] === '-') {
                    arr.push('-');
                } else if (calc[key] === '(') {
                    arr.push('(');
                } else if (calc[key] === ')') {
                    arr.push(')');
                } else {
                    if(typeof calc[key] == 'number') {
                        arr.push(calc[key]);
                    } else {
                        const op = this.findObjectById(calc[key], this.form );
                        if (typeof op !== 'undefined') {
                            if (op.active) {
                                arr.push(Number(op.value));
                            } else {
                                if (arr[arr.length -1] === "*" || arr[arr.length -1] === "/") {
                                    arr.push(1);
                                } else {
                                    arr.push(0);
                                }
                            }
                        } else {
                            console.error("calc", calc[key]);
                        }
                    }
                }
            }
            field.value = eval( arr.join(" ") );
            // console.log(field.id, arr)
            return eval( arr.join(" "));
        },
        variableValue (field) {
            if (!field.variableValue) { return; }
            const ifs = field.variableValue.if;
            const operator = {
                'isBetween': (a, b, c) => a >= b[0] && a <= b[1],
                'isGreater': (a, b) => a > b
            };

            for (const index in ifs) {
                const a = this.findObjectById(ifs[index].condition[0], this.form).value
                const opName = ifs[index].condition[1];
                const b = ifs[index].condition[2];
                
                const result = operator[opName](a, b);
                if (result) {
                    field.value = ifs[index].value;
                    return ifs[index].value;
                }
            }
            field.value = field.variableValue.else;
            return field.variableValue.else;
        },
        conditions (field, conditions) {
            if (!conditions) {return true;}
            const logics = conditions.logics || [];
            if (logics.length < 0) {return true;}

            const operator = {
                'isEqual': ( a, b ) => a === b,
                'isGreater': ( a, b ) => a > b,
                'isLower': ( a, b ) => a < b,
                'isEqualToCheckbox': function( a, b ) {return JSON.stringify(a) === JSON.stringify(b)}
            };

            const form = this.form;

            const findObjById = this.findObjectById;

            const output = [];

            for (const logic in logics) {
                const arr = [];
                for (const logicAnd in logics[logic]) {
                    const a = findObjById(logics[logic][logicAnd].field, form).value
                    const b = logics[logic][logicAnd].value;
                    arr.push(operator[ logics[logic][logicAnd].operator ](a, b));
                }
                output.push(arr.every(a => a));
            }

            field.active = output.some(a => a);
            return output.some(a => a);
        },
        inheritValue (field) {
            let arr = [];
            for (const key in field.inheritValue) {
                arr = [];
                for (const k in field.inheritValue[key].if) {
                    arr.push(field.inheritValue[key].if[k].value === this.findObjectById(field.inheritValue[key].if[k].id, this.form ).value)
                }
                const is = arr.every(a => a);
                if (is) {
                    field.value = field.inheritValue[key].value;
                    return field.value;
                }
            }
        },
        i18n (obj, key) {
            if (obj.i18n) {
                if (obj.i18n[this.form.data.language]) {
                    if (obj.i18n[this.form.data.language][key]) {
                        return obj.i18n[this.form.data.language][key];
                    }
                }
            }
            return obj[key];
        },
        i18nErrors (errors) {
            if (errors.includes('required')) {
                return [this.form.data.i18nErrors[this.form.data.language].required];
            }
            if (errors.includes('email')) {
                return [this.form.data.i18nErrors[this.form.data.language].email];
            }
            if (errors.includes('phone')) {
                return [this.form.data.i18nErrors[this.form.data.language].phone];
            }
            return [];
        }
    },
  }
</script>

<style>
    .field-group {
        border: 1px solid #ddd !important;
        padding: 16px;
    }
</style>