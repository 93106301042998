var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.field.type === 'text' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
            required: _vm.field.required
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","validate-on":"blur","dense":"","required":_vm.field.required,"error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,959337365)}):_vm._e(),(_vm.field.type === 'mail' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.i18n(_vm.field, 'label'),"rules":{
            required: _vm.field.required,
            email: 'email'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","dense":"","required":"","error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,800043621)}):_vm._e(),(_vm.field.type === 'phone' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
            required: _vm.field.required,
            phone: 'phone'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","dense":"","required":"","error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,800043621)}):_vm._e(),(_vm.field.type === 'combobox' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
            required: _vm.field.required
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-combobox',{attrs:{"items":_vm.field.name === 'categorie-expozant' ? _vm.categoriiExpozant : _vm.items,"label":_vm.i18n(_vm.field, 'label'),"multiple":"","filled":"","dense":"","required":"","hint":_vm.i18n(_vm.field, 'hint'),"persistent-hint":"","small-chips":"","error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,830313499)}):_vm._e(),(_vm.field.type === 'combobox2' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
            required: _vm.field.required
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-combobox',{attrs:{"items":_vm.field.items.map(item => _vm.i18n(item, 'label')),"label":_vm.i18n(_vm.field, 'label'),"multiple":"","filled":"","dense":"","required":"","hint":_vm.i18n(_vm.field, 'hint'),"persistent-hint":"","small-chips":"","error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,1044043312)}):_vm._e(),(_vm.field.type === 'radio' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.i18n(_vm.field, 'label'),"rules":{
            required: _vm.field.required
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-radio-group',{attrs:{"error-messages":_vm.i18nErrors(errors),"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"subtitle-1 font-weight-black"},[_vm._v(_vm._s(_vm.i18n(_vm.field, 'label')))])]},proxy:true}],null,true),model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}},_vm._l((_vm.field.items),function(item){return _c('v-radio',{key:item.value,attrs:{"label":_vm.i18n(item, 'label'),"value":item.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('div',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.i18n(item, 'label')))]),_c('div',{staticClass:"font-weight-regular caption"},[_vm._v(_vm._s(_vm.i18n(item, 'hint')))])])]},proxy:true}],null,true)})}),1)]}}],null,false,1849476907)}):_vm._e(),(_vm.field.type === 'select' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.i18n(_vm.field, 'label'),"rules":{
            required: _vm.field.required
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"items":_vm.field.items,"item-text":"label","item-value":"value","hint":_vm.i18n(_vm.field, 'hint'),"persistent-hint":"","filled":"","error-messages":errors,"required":""},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,1213396711)}):_vm._e(),(_vm.field.type === 'checkbox' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
            required: _vm.field.required
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"subtitle-1 font-weight-black"},[_vm._v(_vm._s(_vm.i18n(_vm.field, 'label')))]),_c('div',{staticClass:"mb-6"},_vm._l((_vm.field.items),function(item){return _c('v-checkbox',{key:item.value,attrs:{"label":_vm.i18n(item, 'label'),"value":item.value,"messages":item.hint,"id":item.value,"disabled":(_vm.field.conditions && _vm.field.conditions.mode === 'disable' ? _vm.conditions(_vm.field, _vm.field.conditions) : false),"hide-details":!!!item.hint,"multiple":"","error-messages":_vm.i18nErrors(errors)},on:{"change":_vm.checkboxChange},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})}),1)]}}],null,false,3831589694)}):_vm._e(),(_vm.field.type === 'number' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
            required: _vm.field.required
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","dense":"","required":"","hint":_vm.field.hint,"type":"number","min":_vm.field.min,"error-messages":errors},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,1378961580)}):_vm._e(),(_vm.field.type === 'paragraph' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('div',[_c('p',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.i18n(_vm.field, 'value')))])]):_vm._e(),(_vm.field.type === 'price' && (_vm.field.conditions && _vm.field.conditions.mode === 'show' ? _vm.conditions(_vm.field, _vm.field.conditions) : true))?_c('div',[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.i18n(_vm.field, 'label'))+" "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary"}},[(_vm.field.calc)?_c('span',[_vm._v(" "+_vm._s(_vm.calc(_vm.field, _vm.field.calc).toFixed(2))+" "+_vm._s(_vm.i18n(_vm.field, 'suffix'))+" ")]):(_vm.field.inheritValue)?_c('span',[_vm._v(" "+_vm._s(_vm.inheritValue(_vm.field).toFixed(2))+" "+_vm._s(_vm.i18n(_vm.field, 'suffix'))+" ")]):(_vm.field.variableValue)?_c('span',[_vm._v(" "+_vm._s(_vm.variableValue(_vm.field).toFixed(2))+" "+_vm._s(_vm.i18n(_vm.field, 'suffix'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.field.value.toFixed(2))+_vm._s(_vm.i18n(_vm.field, 'suffix'))+" ")])])],1)]):_vm._e(),(_vm.field.type === 'fieldgroup')?_c('div',[_c('field-group',{attrs:{"form":_vm.form,"field":_vm.field}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }