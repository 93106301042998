<template>

    <div style="background-color: #FFFFFF;">

        <!-- <v-parallax
    src="../assets/banner-test.jpg"  height="200"
  >
    <div class="d-flex flex-column justify-center align-center text-white" height="200">
      <h1 class="text-h4 font-weight-thin mb-4">
        {{i18n(form.data, 'eventTitle')}}
      </h1>
      <h4 class="text-h3 font-weight-black">
        {{form.data.eventName}}
      </h4>
    </div>
  </v-parallax> -->

  <v-divider></v-divider>

    <v-container>
        <v-row>
            <v-col cols="12" align="center">
                <v-responsive max-width="1024px" align="left" style="background-color: #FFFFFF;">

                    <validation-observer
                        ref="observer"
                        v-slot="{ invalid }"
                    >

                        <v-container class="mb-4" >
                            <v-row>
                                <v-col cols="12">

                                    <v-sheet class="d-flex bg-surface-variant field-group">
                                        <v-sheet class="me-auto">
                                            <v-btn
                                                v-if="step > 0"
                                                variant="text"
                                                @click="step--"
                                            >
                                                <v-icon>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </v-sheet>

                                        <v-sheet class="" style="width: 99%;" align="center">
                                            <div class="subtitle-1 font-weight-black">{{form.sections[step].label}}</div>
                                        </v-sheet>

                                        <v-sheet class="">
                                            <v-btn
                                                v-if="step < form.sections.length -1"
                                                color="primary"
                                                variant="flat"
                                                @click="step++"
                                            >
                                                <v-icon>mdi-arrow-right</v-icon>
                                            </v-btn>
                                        </v-sheet>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-form @submit.prevent="submit">
                            <v-window v-model="step">
                                <v-window-item :value="index" :key="section.id" v-for="(section, index) in form.sections">
                                    <v-container class="pa-5 mb-6">
                                        
                                        <v-row class="my-0 py-0">

                                            <v-col
                                                v-for="field in section.fields" :key="field.id"
                                                cols="12"
                                                class="my-0 mb-4 py-0"
                                                :sm="field.width ? field.width.sm : 12"
                                                :md="field.width ? field.width.md : 12"
                                            >
                                                <field-types :form="form" :field="field"></field-types>
                                            </v-col>

                                        </v-row>

                                    </v-container>
                                </v-window-item>
                            </v-window>

                            <v-sheet class="d-flex mb-6 bg-surface-variant">
                                <v-sheet class="me-auto">
                                    <v-btn
                                        v-if="step > 0"
                                        variant="text"
                                        @click="step--"
                                    >
                                        {{form.sections[step -1].label ||  "&#x2190;"}}
                                    </v-btn>
                                </v-sheet>

                                <v-sheet class="">
                                    <v-btn
                                        v-if="step < form.sections.length -1"
                                        color="primary"
                                        variant="flat"
                                        @click="step++"
                                    >
                                        {{form.sections[step +1].label || "&#x2192;"}}
                                    </v-btn>
                                </v-sheet>
                            </v-sheet>

                        </v-form>

                    </validation-observer>
                </v-responsive>
            </v-col>
        </v-row>


    </v-container>
    
    </div>

</template>

<script>
    import { required, email, regex } from 'vee-validate/dist/rules';
    import { extend, ValidationObserver, setInteractionMode } from 'vee-validate';
    import FieldTypes from './fields/FieldTypes.vue'

    setInteractionMode('eager');

    export default {
        name: 'TheForm',
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        components: {
            ValidationObserver,
            FieldTypes
        },
        data: () => ({
            step: 0
        }),
        mounted () {
            extend('required', {
                ...required,
                message: 'required',
            })

            extend('email', {
                ...email,
                message: 'email',
            })

            extend('regex', {
                ...regex,
                message: 'phone',
            })
        },
        methods: {
            submit () {
                this.$refs.observer.validate()
            },
            i18n (obj, key) {
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            }
        }
    }
</script>

